.xcd-loader{
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 1111;
}

.xcd-loader-image{
    height: 5rem;
}
.xcd-loader-icon{
    position: fixed;
    top:44vh;
    left:50vw;
}
